/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
	line-height: 1;
	overflow-y: hidden;
	background-color: transparent;
}
html, body {
	height: 100%;
}
ol, ul {
	list-style: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
tr, th {
	vertical-align: middle !important;
}

blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.btn:focus,.btn:active:focus,.btn.active:focus,
.btn.focus,.btn:active.focus,.btn.active.focus {
	outline: none;
    box-shadow:none;
}

a.disabled {
	opacity: 0.6 !important;
    cursor: default !important;
}


.blue-color {
    color: #00a8db !important;
}

.blue-background {
    background-color: #00a8db !important;
}

.red-color {
    color: #ff585f !important;
}

.red-background {
    background-color: #ff585f !important;
}

.green-color {
    color: #00ce88 !important;
}

.green-background {
    background-color: #00ce88 !important;
}

.dark-green-color {
    color: #008f5f !important;
}

.dark-green-background {
    background-color: #008f5f !important;
}

.orange-color {
    color: #ff5722 !important;
}

.orange-background {
    background-color: #ff5722 !important;
}

.yellow-color {
    color: #ffc300 !important;
}

.yellow-background {
    background-color: #ffc300 !important;
}

.dark-blue-color {
    color: #007bff !important;
}

.dark-blue-background {
    background-color: #007bff !important;
}

.purple-color {
    color: #8e24aa !important;
}

.purple-background {
    background-color: #8e24aa !important;
}

.grey-color {
    color: #696969 !important;
}

.grey-background {
	background-color: #696969 !important;
}

bs-datepicker-container {
	z-index: 1100;
}

.datepicker-today {
	background-color: rgba(189, 221, 230, 0.71);
	
	&:hover {
		background-color:rgba(189, 221, 230, 0.71) !important;
	}
}

.bs-timepicker-field {
	font-size: 13px;
}

.datepicker-form-group {
	position: relative;

	input:disabled+.datepicker-clear {
		pointer-events: none;
	}

	.datepicker-clear {
		pointer-events: all;
		cursor: pointer;
		height: calc(1.5em + 0.75rem + 2px);
		display: flex;
		align-items: center;
		position: absolute;
		right: 0.5rem;
		bottom: 0;

		i {
			font-size: 13px;
		}
	}
}

.OT_root {
	.OT_widget-container {
		.OT_video-poster {
			z-index: unset !important;
			background-image: url(./assets/person.svg) !important;
			opacity: .45 !important;
			background-size: auto 40% !important;
		}
		.OT_video-loading {
			z-index: unset !important;
		}
	}
}

.tutorial-active {
	z-index: 251 !important;
}

.error-page-container {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif;

    .logo-container {
        font-size: 36px;
        display: flex;
        align-items: center;
    }

    .error-page-title {
        font-size: 30px;
		text-align: center;
    }

    .error-page-message {
        font-size: 18px;
		text-align: center;
    }
}

.legal-page-container {
    font-family: 'Raleway', sans-serif;
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    .bar-container {
        max-width: 900px;
        margin: 70px auto 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .logo-container {
            display: flex;
            align-items: center;
    
            .logo-image {
                width: 36px;
                height: 36px;
			}
			
			.logo-only-image {
				height: 48px;
				&.wide {
                    height: 72px;
                }
			}
    
            span {
                font-size: 30px;
                margin-left: 10px;
            }
        }

        .back-link {
            font-weight: 600;
        }
    }

    .legal-page-content {
        max-width: 900px;
        margin: 0 auto 100px;

        .legal-page-title {
            font-size: 28px;
            font-weight: 600;
            text-align: center;
            margin: 16px 0;
        }
        
        .legal-page-title-secondary {
            font-size: 22px;
            font-weight: 600;
            margin: 16px 0;
        }

        .legal-page-title-third {
            font-size: 18px;
            font-weight: 600;
            margin: 10px 0;
        }

        p {
            margin-bottom: 10px;

            i {
                font-size: 14px;
            }
        }

        ul {
            list-style: disc;
            margin-left: 30px;

            li {
                margin-bottom: 5px;
            }
		}
    }
}

#form-control {
	min-height: 36px;
}

@keyframes color-highlight {
    0% {
        color: rgba(255, 255, 255, 1);
	}

    50% {
        color: rgba(255, 255, 255, 0.5);
	}

    100% {
        color: rgba(255, 255, 255, 1);
    }
}

@keyframes color-highlight-black {
    0% {
        color: rgba(0, 0, 0, 1);
	}

    50% {
        color: rgba(0, 0, 0, 0.5);
	}

    100% {
        color: rgba(0, 0, 0, 1);
    }
}

.animated-btn-group {
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	&.animated-group-sm {
		.group-wrapper {
			.group-toggle {
				width: 28px;
				height: 28px;

				i {
					color: white;
					font-size: 16px;
				}
			}
			.group-list {
				padding-bottom: 30px;
				padding-top: 2px;
				border-radius: 14px;
				transform-origin: 14px calc(100% - 14px);

				&.list-reverse {
					padding-top: 30px;
					padding-bottom: 2px;
					transform-origin: 14px 14px;
				}

				.group-btn {
					width: 22px;
					height: 22px;

					i {
						color: white;
						font-size: 14px;
					}
				}
			}
		}
	}

	&.animated-group-xl {
		.group-wrapper {
			.group-toggle {
				width: 36px;
				height: 36px;

				i {
					color: white;
					font-size: 20px;
				}
			}
			.group-list {
				padding-bottom: 38px;
				padding-top: 5px;
				border-radius: 18px;
				transform-origin: 18px calc(100% - 18px);

				&.list-reverse {
					padding-top: 38px;
					padding-bottom: 5px;
					transform-origin: 18px 18px;
				}
	
				.group-btn {
					width: 30px;
					height: 30px;

					i {
						color: white;
						font-size: 18px;
					}
				}
			}
		}
	}

	&.animated-group-vertical {
		flex-direction: column;
		align-items: flex-start;

		&.animated-group-sm {
			.group-wrapper {
				.group-list {
					padding-left: 30px;
					padding-right: 2px;

					&.list-reverse {
						padding-right: 30px;
						padding-left: 2px;
					}
				}
			}
		}

		&.animated-group-xl {
			.group-wrapper {
				.group-list {
					padding-left: 38px;
					padding-right: 5px;

					&.list-reverse {
						padding-right: 38px;
						padding-left: 5px;
					}
				}
			}
		}

		.group-wrapper {
			margin-top: 4px;
			margin-left: 0;

			&:first-child {
				margin-top: 0;
				margin-left: 0;
			}

			&.expand {
				.group-list {
					transform: scaleX(1);
				}
			}

			.group-list {
				flex-direction: row;
				transform: scaleX(0);
				transform-origin: 16px 16px;
				padding-bottom: 0;
				padding-top: 0;
				padding-left: 34px;
				padding-right: 4px;
				top: 0;
				bottom: auto;
				right: auto;
				left: 0;

				&.group-list-multi {
					flex-direction: column;
					transform: scale(0);

					.group-list-group {
						flex-direction: row;
					}
				}

				&.group-list-wide {
					transform: scale(0);
				}

				&.list-reverse {
					padding-top: 0;
					padding-bottom: 0;
					right: 0;
					left: auto;
					bottom: auto;
					padding-right: 34px;
					padding-left: 4px;
					transform-origin: calc(100% - 16px) 16px;
				}

				.group-btn {
					margin: 3px 0;
				}
			}
		}
	}

	.group-wrapper {
		position: relative;
		margin-left: 4px;
		pointer-events: none;

		&:first-child {
			margin-left: 0;
		}

		&.expand {
			.group-list {
				transform: scaleY(1);

				&.group-list-multi {
					transform: scale(1);
				}
				&.group-list-wide {
					transform: scale(1);
				}
			}
		}

		.group-toggle {
			width: 32px;
			height: 32px;
			opacity: 0.99;
			background-color: rgba(0, 0, 0, 0.7);
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			pointer-events: all;
			transition: width 0.4s, height 0.4s linear;

			&:hover {
				background-color: rgba(0, 0, 0, 0.9);
			}

			i {
				color: white;
				font-size: 18px;
				transition: font-size 0.4s linear;
			}
		}

		.group-list {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			top: auto;
			left: 0;
			bottom: 0;
			padding-bottom: 34px;
			padding-top: 4px;
			overflow: hidden;
			background-color: rgba(0, 0, 0, 0.5);
			border-radius: 16px;
			transform-origin: 16px calc(100% - 16px);
			transform: scaleY(0);
			transition: transform 0.2s ease-in, padding 0.4s linear, border-radius 0.4s linear;
			pointer-events: all;

			&.group-list-multi {
				flex-direction: row;
				transform: scale(0);

				.group-list-group {
					display: flex;
					flex-direction: column;
				}
			}
			&.group-list-wide {
				transform: scale(0);
			}

			&.list-reverse {
				top: 0;
				bottom: auto;
				padding-top: 34px;
				padding-bottom: 4px;
				transform-origin: 16px 16px;
			}

			.group-btn {
				width: 26px;
				height: 26px;
				margin: 0 3px;
				background-color: rgba(0, 0, 0, 0.2);
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				transition: width 0.4s, height 0.4s linear;

				&.active {
					background-color: rgba(0, 0, 0, 0.5);
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.5);
				}

				i {
					color: white;
					font-size: 16px;
					transition: font-size 0.4s linear;
				}
			}
		}
	}
}

.stats-popover {
	background: #0f0f0f;
	border-color: #0f0f0f;
	min-width: 120px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

	.popover-body {
		color: white !important;
		font-family: 'Raleway', sans-serif;

		a {
			color: #ffffff;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			cursor: pointer;
			float: right;

			&:hover {
				color: #d1d1d1;
			}
		}
	}

	&.popover-top {
		.popover-arrow::before {
			border-bottom-color: #0f0f0f;
		}
	
		.popover-arrow::after {
			border-top-color: #0f0f0f;
		}
	}

	&.popover-bottom {
		.popover-arrow::before {
			border-top-color: #0f0f0f;
		}
	
		.popover-arrow::after {
			border-bottom-color: #0f0f0f;
		}
	}

	&.popover-right {
		.popover-arrow::before {
			border-left-color: #0f0f0f;
		}

		.popover-arrow::after {
			border-right-color: #0f0f0f;
		}
	}

	&.popover-left {
		.popover-arrow::before {
			border-right-color: #0f0f0f;
		}

		.popover-arrow::after {
			border-left-color: #0f0f0f;
		}
	}
}

.tutorial-popover {
	background: #46a3ca;
	border-color: #46a3ca;
	min-width: 120px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

	.popover-body {
		color: white !important;
		font-family: 'Raleway', sans-serif;

		a {
			color: #ffffff;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			cursor: pointer;
			float: right;

			&:hover {
				color: #d1d1d1;
			}
		}
	}

	&.popover-top {
		.popover-arrow::before {
			border-bottom-color: #46a3ca;
		}
	
		.popover-arrow::after {
			border-top-color: #46a3ca;
		}
	}

	&.popover-bottom {
		.popover-arrow::before {
			border-top-color: #46a3ca;
		}
	
		.popover-arrow::after {
			border-bottom-color: #46a3ca;
		}
	}

	&.popover-right {
		.popover-arrow::before {
			border-left-color: #46a3ca;
		}

		.popover-arrow::after {
			border-right-color: #46a3ca;
		}
	}

	&.popover-left {
		.popover-arrow::before {
			border-right-color: #46a3ca;
		}

		.popover-arrow::after {
			border-left-color: #46a3ca;
		}
	}
}

.modal {
	&.show {
		display: flex !important;
		align-items: center;
		justify-content: center;
		z-index: 600 !important;
	}
}
 .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 599;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,.8) !important;

	&.show {
		opacity: 1;
	}
}
.modal + .modal {
	background: rgba(255,255,255,.8);
  }

.modal-dialog {
    font-family: 'Raleway', sans-serif;
    width: 400px;

	&.modal-lg {
		width: 800px;
	}
    .modal-content {
		border: none;
		border-radius: 0;
        -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.35);
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.35);
		max-height: 90vh;

        .modal-header{
			background: #343a40;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			border: none;
			border-top-left-radius: 0;
			border-top-right-radius: 0;

            .modal-title {
                font-size: 18px;
                color: white;
            }
        }

        .modal-body {
			overflow-y: auto;
			font-size: 13px;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			padding-right: 1rem;
			padding-left: 1rem;
		}

		.modal-footer {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;

			.btn, .btn-sm {
				border-radius: 0;
			}
		}
	}
}

.ng-select {
	font-family: 'Raleway', sans-serif;

	&.ng-select-disabled>.ng-select-container {
		background-color: #e9ecef;
	}

	.ng-select-container {
		//height: calc(1.5em + 0.75rem + 2px) !important;
		min-height: calc(1.5em + 0.75rem + 2px) !important;
	}

	&.ng-select-single {
		.ng-select-container {
			.ng-value-container {
				.ng-placeholder {
					font-size: 12px;
				}
				.ng-value {
					.ng-value-label {
						font-size: 12px;
					}
				}
			}
		}
		.ng-dropdown-panel {
			font-size: 12px;
		}
	}

	&.ng-select-multiple {
		.ng-select-container {
			.ng-value-container {
				.ng-placeholder {
					font-size: 14px;
					top: 50% !important;
					transform: translate(0, -50%) !important;
					padding: 0 0 0 3px !important;
				}
				.ng-value {
                    .ng-value-label {
                        font-size: 13px;
                    }
					.ng-value-icon {
						font-size: 14px;
						padding: 4px 8px !important;
					}
				}
			}
        }
        .ng-dropdown-panel {
            .ng-dropdown-panel-items {
                .ng-option {
                    .ng-option-label {
                        font-size: 13px !important;
                    }
                }
            }
        }
	}
}

.switch {
	width: 40px;
	height: 25px;
	padding-left: 3px;

	input {
		display: none;
		visibility: hidden;

		&:checked + label:before {
			background-color: #b9b9b9;;
		}

		&:checked + label:after {
			background-color: #777777;
			-ms-transform: translate(80%, -50%);
			-webkit-transform: translate(80%, -50%);
			transform: translate(80%, -50%);
		}

		&:disabled + label:before {
			background-color: #e4e4e4;
		}
	
		&:disabled + label:after {
			background-color: rgb(202, 202, 202);
		}
	}

	label {
		position: relative;
		display: inline-block;
		cursor: pointer;
		text-align: left;

		&:before, &:after {
			content: "";
			position: absolute;
			margin: 0;
			outline: 0;
			top: 50%;
			-ms-transform: translate(0, -50%);
			-webkit-transform: translate(0, -50%);
			transform: translate(0, -50%);
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		}

		&:before {
			left: 1px;
			width: 34px;
			height: 14px;
			background-color: #9E9E9E;
			border-radius: 8px;
		}

		&:after {
			left: 0;
			width: 20px;
			height: 20px;
			background-color: #FAFAFA;
			border-radius: 50%;
			box-shadow: 0 3px 1px -2px #00000024, 0 2px 2px 0 #00000019, 0 1px 5px 0 #00000015;
		}
	}
}

/*
USAGE
<label class="switch-1">
	<input class="switch-input" type="checkbox" />
	<span class="switch-label" data-on="On" data-off="Off"></span> 
	<span class="switch-handle"></span> 
</label>
*/

.switch-2 {
	position: relative;
	display: block;
	width: 100px;
	height: 30px;
	padding: 3px;
	border-radius: 18px;
	cursor: pointer;
	box-sizing:content-box;

	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		box-sizing:content-box;
	}
	.switch-label {
		position: relative;
		display: block;
		height: inherit;
		font-size: 10px;
		text-transform: uppercase;
		background: #eceeef;
		border-radius: inherit;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
		box-sizing:content-box;
	}
	.switch-label:before, .switch-label:after {
		position: absolute;
		top: 50%;
		margin-top: -.5em;
		line-height: 1;
		-webkit-transition: inherit;
		-moz-transition: inherit;
		-o-transition: inherit;
		transition: inherit;
		box-sizing:content-box;
	}
	.switch-label:before {
		content: attr(data-off);
		right: 11px;
		color: #aaaaaa;
	}
	.switch-label:after {
		content: attr(data-on);
		left: 11px;
		color: #FFFFFF;
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label {
		background: #e6c233;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label:after {
		opacity: 1;
	}
	.switch-handle {
		position: absolute;
		top: 4px;
		left: 4px;
		width: 28px;
		height: 28px;
		background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
		background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
		border-radius: 100%;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-handle:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 12px;
		height: 12px;
		background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
		background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
		border-radius: 6px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
	}
	.switch-input:checked ~ .switch-handle {
		left: 74px;
		box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-label, .switch-handle {
		transition: All 0.3s ease;
		-webkit-transition: All 0.3s ease;
		-moz-transition: All 0.3s ease;
		-o-transition: All 0.3s ease;
	}
}
.switch-flat {
	padding: 0;
	background: #FFF;
	background-image: none;

	.switch-label {
		background: #FFF;
		border: solid 2px #eceeef;
		box-shadow: none;
	}
	.switch-label:after {
		color: #0088cc;
	}
	.switch-handle {
		top: 6px;
		left: 6px;
		background: #dadada;
		width: 22px;
		height: 22px;
		box-shadow: none;
	}
	.switch-handle:before {
		background: #eceeef;
	}
	.switch-input:checked ~ .switch-label {
		background: #FFF;
		border-color: #0088cc;
	}
	.switch-input:checked ~ .switch-handle {
		left: 72px;
		background: #0088cc;
		box-shadow: none;
	}
}
.switch-left-right {
	.switch-label {
		overflow: hidden;
	}
	.switch-label:before, .switch-label:after {
		width: 20px;
		height: 20px;
		top: 4px;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 11px 0 0 0;
		text-indent: -12px;
		border-radius: 20px;
		box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
	}
	.switch-label:before {
		background: #eceeef;
		text-align: left;
		padding-left: 80px;
	}
	.switch-label:after {
		text-align: left;
		text-indent: 9px;
		background: #FF7F50;
		left: -100px;
		opacity: 1;
		width: 100%;
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 1;
		left: 100px;
	}
	.switch-input:checked ~ .switch-label:after {
		left: 0;
	}
	.switch-input:checked ~ .switch-label {
		background: inherit;
	}
}

.custom-checkbox {
	min-height: calc(2.25rem + 2px);

	.custom-control-label {
		position: absolute;
		height: 1rem;
		line-height: 1rem;
		font-size: 13px !important;
		top: 50%;
		transform: translate(0, -50%);

		&::before {
			top: 0;
		}
		&::after {
			top: 0;
		}
	}
}

.card-1 {
box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-1:hover {
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25), 0 2px 3px rgba(0, 0, 0, 0.22);
	//box-shadow: 0 5px 10px rgba(0,0,0,0.25), 0 4px 4px rgba(0,0,0,0.22);
}

.card-2 {
box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.loader,
.loader > div {
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.loader {
	display: block;
	font-size: 0;
	color: #fff;
	width: 40px;
	height: 32px;

	&.la-dark {
		color: #333;
	}
	&.la-light {
		color: #fff;
	}

	> div {
		display: inline-block;
		float: none;
		background-color: currentColor;
		border: 0 solid currentColor;
		width: 4px;
		height: 32px;
		margin: 2px;
		margin-top: 0;
		margin-bottom: 0;
		border-radius: 0;
		-webkit-animation: line-scale 1.2s infinite ease;
		-moz-animation: line-scale 1.2s infinite ease;
		-o-animation: line-scale 1.2s infinite ease;
		animation: line-scale 1.2s infinite ease;
	}

	> div:nth-child(1) {
		-webkit-animation-delay: -1.2s;
		-moz-animation-delay: -1.2s;
		-o-animation-delay: -1.2s;
		animation-delay: -1.2s;
	}
	
	> div:nth-child(2) {
		-webkit-animation-delay: -1.1s;
		   -moz-animation-delay: -1.1s;
			 -o-animation-delay: -1.1s;
				animation-delay: -1.1s;
	}

	> div:nth-child(3) {
		-webkit-animation-delay: -1s;
		-moz-animation-delay: -1s;
		-o-animation-delay: -1s;
		animation-delay: -1s;
	}

	> div:nth-child(4) {
		-webkit-animation-delay: -.9s;
		-moz-animation-delay: -.9s;
		-o-animation-delay: -.9s;
		animation-delay: -.9s;
	}

	> div:nth-child(5) {
		-webkit-animation-delay: -.8s;
		-moz-animation-delay: -.8s;
		-o-animation-delay: -.8s;
		animation-delay: -.8s;
	}

	&.loader-sm {
		width: 20px;
		height: 16px;

		> div {
			width: 2px;
			height: 16px;
			margin: 1px;
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&.loader-2x {
		width: 80px;
		height: 64px;

		> div {
			width: 8px;
			height: 64px;
			margin: 4px;
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&.loader-3x {
		width: 120px;
		height: 96px;

		> div {
			width: 12px;
			height: 96px;
			margin: 6px;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.t-container {
	font-family: 'Raleway', sans-serif;
    background-color: white;

    .sm-hide {
        display: none;
    }
    .sm-show {
        display: flex;
    }

    &:not(.t-no-hover) {
        .row.t-row {
            cursor: pointer;

            &:hover {
                background-color: #e2e2e2;
            }
        }
    }
}

.row {
    .t-cell {
		padding: 0.3rem 0.5rem !important;
		
		.sm-block-btn {
			display: block;
			width: 100%;
		}
    }

    &.t-head {
        font-family: 'Raleway', sans-serif;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        align-items: center;
        justify-content: center;
	}
	
	&.t-head.t-draggable {
		padding-left: 2rem !important;
	}

    &.t-row.t-draggable {
		box-shadow: 0px 0px 5px -1px rgba(102,102,102,1);
		padding-left: 2rem !important;

		.sm-show {
			display: flex;
		}

        .drag-handle {
            display: flex;
            visibility: visible;
            opacity: 1;
            align-items: center;
            justify-content: center;

            i {
                color: gray;
                transform: rotate(90deg);
                font-size: 24px;
            }
        }
    }

    &.t-row {
        font-family: 'Raleway', sans-serif;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        position: relative;

        .drag-handle {
            display: none;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 2rem;
        }

        &:nth-of-type(even) {
            background-color: #f9f9f9;
        }

        button {
            padding: 0 6px;
            flex: 1;

            &.btn-warning {
                color: white;
            }

            i {
                margin: 0 4px;
                line-height: 24px;
                font-size: 14px;
                vertical-align: middle;
            }

            span {
                font-size: 12px;
                font-weight: 500;
                margin-right: 8px;
                line-height: 24px;
                vertical-align: middle;
            }
        }
    }
}


@media only screen and (min-width: 992px) {
    .t-container {
        .sm-hide {
            display: flex;
        }
        .sm-show {
            display: none;
        }
    }
    .row {

        .t-cell {
			padding: 0.25rem 0.25rem !important;
			
			.sm-block-btn {
				display: inline-block;
				width: unset;
			}
        }

        &.t-head {
            font-size: 13px;
            font-weight: 500;
            padding-top: 0.75rem !important;
            padding-bottom: 0.75rem !important;
            color: #565a5c !important;

            &.button-pad {
                padding-right: 4rem !important;
            }
        }
        &.t-row {
			&.t-draggable .sm-show {
				display: none;
			}
            
            &.button-pad {
                padding-right: 4rem !important;
            }
            button {
                flex: none;
                padding: 0;

                i {
                    margin: 0 8px;
                }
            }
        }
    }
}

/*
* Animation
*/
@-webkit-keyframes line-scale {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(.4);
				transform: scaleY(.4);
	}
	20% {
		-webkit-transform: scaleY(1);
				transform: scaleY(1);
	}
}
@-moz-keyframes line-scale {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(.4);
		-moz-transform: scaleY(.4);
				transform: scaleY(.4);
	}
	20% {
		-webkit-transform: scaleY(1);
		-moz-transform: scaleY(1);
				transform: scaleY(1);
	}
}
@-o-keyframes line-scale {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(.4);
			-o-transform: scaleY(.4);
				transform: scaleY(.4);
	}
	20% {
		-webkit-transform: scaleY(1);
			-o-transform: scaleY(1);
				transform: scaleY(1);
	}
}
@keyframes line-scale {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(.4);
		-moz-transform: scaleY(.4);
			-o-transform: scaleY(.4);
				transform: scaleY(.4);
	}
	20% {
		-webkit-transform: scaleY(1);
		-moz-transform: scaleY(1);
			-o-transform: scaleY(1);
				transform: scaleY(1);
	}
}

@-moz-keyframes spin-left { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin-left { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin-left { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

@-moz-keyframes spin-right { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin-right { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin-right { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes pound {
	from { transform: none; }
	50% { transform: scale(1.2); }
	to { transform: none; }
}

@media (max-width: 768px) {
  .mat-table[matTableResponsive] {
		width: 100%;
		font-family: "Raleway", sans-serif;

    .mat-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;
		border-bottom-width: 3px;

        & > .mat-sort-header-container {
          padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled {
          display: none;
        }
      }
    }

    .mat-row {
      height: 149px;
			cursor: pointer;
			
			&:nth-of-type(odd) {
				background-color: #f9f9f9;
			}
			
			&:hover {
				background-color: #e2e2e2;
		}

      td.mat-cell {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        word-break: break-word;
		font-size: 13px;
		border-bottom-width: 0px;
		height: 24px;
		font-family: 'Raleway', sans-serif;
		color: #72777a;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
        }

				.badge {
					font-weight: 400;
					width: fit-content;
				}
      }

      &:last-of-type td:last-of-type {
        border: none;
      }
    }
  }

  .mat-card,
  .mat-expansion-panel {
    .mat-table[matTableResponsive] {
      border: 0.5rem solid #fafafa;
    }
  }
}

@media (min-width: 769px) {
	.mat-table[matTableResponsive] {
		width: 100%;
        font-family: "Raleway", sans-serif;
		.mat-header-row {
			height: 36px;

			th.mat-header-cell:first-of-type {
				padding-left: 0;
			}

			th.mat-header-cell:last-of-type {
				padding-right: 10px;
			}

		}
		.mat-row {
			cursor: pointer;
			height: 36px;

			&:nth-of-type(odd) {
				background-color: #f9f9f9;
			}

			&:hover {
				background-color: #e2e2e2;
		}

			td.mat-cell {
				font-size: 12px;
				border-bottom-width: 0px;
				font-family: 'Raleway', sans-serif;
				color: #212529;

				.badge {
					font-weight: 400;
				}

				&:first-of-type {
					padding-left: 0;
				}

				&:last-of-type {
					padding-right: 10px;
				}

			}

		}
	}

    .mat-header-cell {
	font-size: 13px;
	color: #565a5c !important;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	font-weight: 700;
    }

	td.mat-column-room_id {
		display: flex;
		height: inherit;
		align-items: center;
	}

	.mat-column-index {
		width: 5%;
		text-align: center !important;
	}

	.mat-column-title {
		padding-left: 1% !important;
	}
}
th.mat-header-cell {
	border-bottom-width: 3px !important;
	border-bottom-style: solid;
	border-color: #ececec;
  }

.main-header{
    .span {
        font-size: 16px;
    }
}
.main-wrapper{
    font-family: 'Raleway', sans-serif !important;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #ececec;

    .t-container {
        background-color: white;

        .t-head {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }

        .t-row {
            padding-top: 0.3rem !important;
            padding-bottom: 0.3rem !important;
        }
    }
}
 .empty-list{
	padding: 47px;
 }
 .ticket-list-empty {
	text-align: center;
	font-size: 14px;
	color: #565a5c;
}
.loading-template{
	background-color: white; padding: 55px;
}

.bs-popover-left.in.left.popover.popover-body.popover-left.show {
	left: -17rem !important;
  top: -0.5rem !important;
  width: 100%;
}